import React, { useEffect, useState, Suspense } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./helper/ScrollToTop";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper/Theme.js";
import Loader from "./components/Common/Loader/Loader";
import SlotManagement from "./pages/Slot Management/SlotManagement.js";
import WaitingList from "./pages/Waitinglist Management/WaitingList.js";
import Waitlist_Details from "./pages/Waitlist Details/Waitlist_Details.js";
import Profile from "./pages/Profile/Profile.js";
import Appointment_Slot_booking from "./pages/Appointment Slot booking/Appointment_Slot_booking.js";
import SearchAgency from "./pages/Search Agency/SearchAgency.js";
import User_Management from "./pages/User-Mangement/User_Management.js";
import Agency_Mangement from "./pages/Agency-Management/Agency_Mangement.js";
import Register from "./pages/Login&Register/Register.js";
import Waitlist_Status from "./pages/Waitlist Status/Waitlist_Status.js";
import Service_Management from "./pages/Service Section/Service Management/Service_Management.js";
import Service_Availability from "./pages/Service Section/Service Availability/Service_Availability.js";
import Search_for_Services from "./pages/Service Section/Search for Services/Search_for_Services.js";
import Referral_Management from "./pages/Referral  Section/Referral Management/Referral_Management.js";
import Referral_Updates from "./pages/Referral  Section/Referral Updates/Referral_Updates.js";
import Create_Service from "./pages/Waitlist Status/Create_Service.js";
import Plan from "./pages/Plan/Plan.js";
import Agency_Profile from "./pages/Agency-Profile/Agency_Profile.js";
import { useDispatch, useSelector } from "react-redux";
import Notification from "./components/Common/Notification/Notification.js";
import { loadUser } from "./Service/auth.service.js";
// import { useUserNotificationSubscribeHook } from "./Notification.js";
import { loginUserApi } from "./state/Action/authAction.js";
import AgencyDetailView from "./pages/Agency-Management/AgencyDetailView.js";
import Total_Referrals from "./pages/Referral  Section/Referral Management/Total_Referrals.js";
import Active_Referrals from "./pages/Referral  Section/Referral Management/Active_Referrals.js";
import Participating_Agencies from "./pages/Referral  Section/Referral Management/Participating_Agencies.js";
import TotalAgency from "./pages/Agency-Management/Total_Agency.js";
import View_Waitlist_Status from "./pages/Waitlist Status/View_Waitlist_Status.js";
import UserProfile from "./pages/Profile/UserProfile.js";
import Add_To_Cart from "./pages/Cart Page/Add_To_Cart.js";
import Check_out from "./pages/Cart Page/Check_out.js";
import Our_Plan from "./pages/Cart Page/Our_Plan.js";
import Success from "./pages/Cart Page/Success.js";
import Fail from "./pages/Cart Page/Fail.js";
import NotFound from "./pages/404/NotFound.js";
import AgencyRegister from "./pages/Login&Register/AgencyRegister.js";


const Login = React.lazy(() => import("./pages/Login&Register/Login.js"));
const Layout = React.lazy(() => import("./components/layouts/Layout"));
const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard.js"));

const pages = [
  { name: "register", path: "register", element: <Register /> },
  { name: "dashboard", path: "dashboard", element: <Dashboard /> },
  { name: "slotmanagement", path: "slot-management", element: <SlotManagement /> },
  { name: "waitinglist", path: "waitlist-management", element: <WaitingList /> },
  { name: "waitinglist", path: "View-waitlist-status", element: <View_Waitlist_Status /> },

  { name: "waitingdetails", path: "waitlist-details", element: <Waitlist_Details /> },
  { name: "profile", path: "profile", element: <Profile /> },
  { name: "profile", path: "user-profile", element: <UserProfile /> },

  { name: "slotbooking", path: "slot-booking", element: <Appointment_Slot_booking />, },
  { name: "wailtlist Status", path: "waitlist-status", element: <Waitlist_Status />, },
  { name: "Search Angecy", path: "search-agency", element: <SearchAgency /> },
  { name: "Usermanagement", path: "user-management", element: <User_Management />, },
  { name: "AgencyManagement", path: "agency-management", element: <Agency_Mangement /> },

  { name: "ServiceManagement", path: "service-management", element: <Service_Management /> },
  { name: "ServiceAvailable", path: "service-available", element: <Service_Availability /> },
  { name: "SearchforService", path: "search-service", element: <Search_for_Services /> },

  { name: "Referal Management", path: "referral-management", element: <Referral_Management /> },
  { name: "Total Referal", path: "total-referrals", element: <Total_Referrals /> },
  { name: "Active referral", path: "active-referrals", element: <Active_Referrals /> },
  { name: "Participating agency", path: "participating-agency", element: <Participating_Agencies /> },
  { name: "Referal Update", path: "referral-update", element: <Referral_Updates /> },


  { name: "Create Service", path: "create-service", element: <Create_Service /> },
  { name: 'plan', path: 'plan', element: <Plan /> },
  { name: 'AgencyProfile', path: 'agency-profile', element: <Agency_Profile /> },
  { name: 'agecydetails', path: 'agency-details', element: <AgencyDetailView /> },
  { name: 'total agency', path: 'total-agency', element: <TotalAgency /> },
  { name: 'user', path: 'users', element: <User_Management /> },

  // {name:'Add-to-Cart',path:'add-to-cart',element:<Add_To_Cart/>},
  // {name:'Checkout',path:'check-out',element:<Check_out/>},
  // {name:'choose plan',path:'choose-plan',element:<Our_Plan/>},
  // {name:'success',path:'success',element:<Success/>},

];

function App() {

  const { show } = useSelector(state => state?.root?.notification);
  const { user } = useSelector((state) => state?.root?.auth);
  const [roleName, setRoleName] = useState("admin");
  // const { registerAndSubscribe } = useUserNotificationSubscribeHook();

  const Navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { pathname } = location;


  const [isRegister, setIsRegister] = useState(0);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });

  useEffect(() => {
    if (pathname == "/" && user && user?.role && user?.role == 1) {
      Navigate("/dashboard");
    }
    else if (pathname == "/" && user && user?.role && user?.role == 2) {
      Navigate("/dashboard");
    }
    else if (pathname == "/" && user && user?.role && user?.role == 3) {
      Navigate("/waitlist-status");
    }
    setRoleName(user && user?.role);
  }, [user, pathname]);


  useEffect(() => {
    if (show && show.show) {
      setShowNotification(show);
    }
  }, [show]);

  const getUsetData = async (data) => {
    let res = await loadUser(data);
    if (res?.status) {
      // registerAndSubscribe(res?.data && res?.data?.id);
      dispatch(loginUserApi(res?.data)).then(() => {
        setIsUserLoaded(true);
      }).catch(() => {
        setIsUserLoaded(true);
      })
    } else {
      setIsUserLoaded(true);
    }
  };


  useEffect(() => {
    const token = localStorage.getItem("token");
    const payload = {
      token: token,
    };
    getUsetData(payload);
  }, []);
  return (
    <>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <div className="background_color">
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route
                  path="/"
                  element={
                    isRegister == 1 ? (
                      <Register setIsRegister={setIsRegister} onLoginClick={() => setIsRegister(false)} />
                    ) : isRegister == 2 ? (
                      <AgencyRegister onLoginClick={() => setIsRegister(false)} setIsRegister={setIsRegister} />
                    ) :
                      (
                        <Login onRegisterClick={() => setIsRegister(true)} setIsRegister={setIsRegister} />
                      )
                  }
                />

                {pages?.map((page, index) => {
                  const { name, path, element } = page;

                  return (
                    <Route
                      key={index}
                      exact
                      path={`/${path}`}
                      name={name}
                      element={
                        // <PrivateRoute>
                        <Layout>{element}</Layout>
                        // </PrivateRoute>
                      }
                    />
                  );
                })}

                <Route path="*" element={<NotFound />} />
                <Route path="/fail" element={<Fail />} />
                <Route path="/success" element={<Success />} />
                <Route path="/add-to-cart" element={<Add_To_Cart />} />
                <Route path="/check-out" element={<Check_out />} />
                <Route path="/choose-plan" element={<Our_Plan />} />
              </Routes>
            </Suspense>
          </div>
          {showNotification && showNotification.show ? <Notification show={showNotification} setShow={setShowNotification} /> : null}
        </ThemeProvider>
      </ScrollToTop>
    </>
  );
}

export default App;
