import React, { useEffect, useState } from "react";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  FormControl,
  Typography,
  Stack,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Box,
  Tooltip,
  Collapse,
  Select,
  MenuItem,
  Switch,
} from "@mui/material";
import { MdDelete, MdEdit, MdSearch } from "react-icons/md";
import { Button, Heading, Input, Modal } from "../../components/Common";
import { CloseIcon } from "../../helper/Icons";
import { VscFilter } from "react-icons/vsc";
import { agencyList, agencyDetail, agencyDelete, agencyStatusChange } from "../../Service/Agency.service";
import { serviceDropdown } from "../../Service/ServiceDepartment.service";
import TableRowsLoader from "../../components/Loader/Skeleton";
import { Pagination } from "../../components/Common/Table";
import { agencyFilterValidationSchema } from "../../helper/validation";
import { agencyFilterInitialValues } from "../../helper/initialValues";
import { useFormik } from "formik";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const TotalAgency = () => {
  const [searchLocation, setSearchLocation] = useState("");
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [sortConfigUser, setSortConfigUser] = useState({ key: "", direction: "" });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalData, setTotalData] = useState(0);
  const [agencyData, setAgencyData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [singleAgencyData, setSingleAgencyData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [select, setselect] = useState("");
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    getServiceData();
  }, []);

  const getServiceData = async () => {
    const response = await serviceDropdown();
    if (response?.status) {
      setServiceData(response?.data);
    } else {
      setServiceData([]);
    }
  };

  const getSingleAgencyData = async (id) => {
    const response = await agencyDetail(id);
    if (response?.status) {
      setSingleAgencyData(response?.data);
    } else {
      setSingleAgencyData([]);
    }
  };

  const deleteAgencyData = async () => {
    const response = await agencyDelete(isModalOpen.id);
    if (response?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: response.message || "Success",
        })
      );
      setIsModalOpen({ open: false, currentComponent: "" });
      getAgencyData();
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message: response.message || "Something went wrong.",
        })
      );
    }
  };

  const changeStatus = async () => {
    console.log("isModalOpen", isModalOpen);
    let res =
      isModalOpen && isModalOpen?.currentComponent == "status"
        ? await agencyStatusChange(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "delete"
          ? await agencyDelete(isModalOpen?.id)
          : null;
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      setIsModalOpen({ open: false, currentComponent: "" });
      getAgencyData();
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };


  console.log("singleAgencyData", singleAgencyData);

  const handleRowClick = (agency) => {
    setSelectedAgency(agency);
    getSingleAgencyData(agency.id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    getAgencyData();
  };

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    getAgencyData();
  }, [search, page, sortConfigUser]);

  const getAgencyData = async () => {
    setAgencyData([]);
    let selectQuery = `?page=${page + 1}&limit=${rowsPerPage}&status=${formik.values.status}&isRegistered=${formik.values.isRegistered}&orderBy=${sortConfigUser.key}&sortFlag=${sortConfigUser.direction == 'asc' ? true : false}`;
    const response = await agencyList(selectQuery);
    if (response?.status) {
      setAgencyData(response?.data);
      setTotalData(response.total);
    } else {
      setAgencyData(null);
    }
  };

  const formik = useFormik({
    initialValues: agencyFilterInitialValues,
    onSubmit: async (values) => {
      getAgencyData();
    },
  });

  return (
    <>
      <Stack gap={2}>
        <Heading head={"Agencies"} />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            width: "100%",
          }}
        >
          <FormControl sx={{ width: { xs: "100%", md: "500px" } }}>
            <Typography>Search</Typography>
            <TextField
              placeholder="Search"
              name="location"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(0);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </FormControl>
          <Box display={"flex"} gap={1}>
            <Button
              variant="contained"
              buttonName={"Create"}
              color="primary"
              onClick={() => {
                setIsModalOpen({
                  open: true,
                  currentComponent: "addAgency",
                });
              }}
            />
            <Tooltip title="Filter">
              <Button
                variant="contained"
                buttonName={<VscFilter />}
                onClick={handleFilterClick}
                color="white"
                sx={{ marginTop: "10px" }}
              />
            </Tooltip>
          </Box>
        </Box>
        <Collapse in={showFilter}>
          <Stack
            sx={{ backgroundColor: "white.main" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1.5}
            alignItems={"center"}
          >
            <Grid className="filterinput" style={{ marginTop: "-10px" }}>
              <Typography>{"Status"}</Typography>
              <Select
                fullWidth
                onChange={(e) => {
                  formik.setFieldValue("status", e.target.value);
                }}
                displayEmpty
                value={formik.values.status}
              >
                <MenuItem value="">Select Status</MenuItem>
                <MenuItem value="1">Active</MenuItem>
                <MenuItem value="0">In Active</MenuItem>
              </Select>
              {/* make one button */}
            </Grid>
            <Grid className="filterinput" style={{ marginTop: "-10px" }}>
              <Typography>{"Register Status"}</Typography>
              <Select
                fullWidth
                onChange={(e) => {
                  formik.setFieldValue("isRegistered", e.target.value);
                }}
                displayEmpty
                value={formik.values.isRegistered}
              >
                <MenuItem value="">Select Register Status</MenuItem>
                <MenuItem value="1">Registered</MenuItem>
                <MenuItem value="0">Not Register</MenuItem>
              </Select>
              {/* make one button */}
            </Grid>
            <Stack gap={1} flexDirection={"row"}>
              <Box>
                <Button
                  color="primary"
                  buttonName={"Filter"}
                  onClick={formik.handleSubmit}
                />
              </Box>
              <Box>
                <Button
                  buttonName={"Clear"}
                  color="tertiary"
                  onClick={() => {
                    setShowFilter(false);
                    formik.resetForm();
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </Collapse>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => setSortConfigUser({ key: "agencyName", direction: sortConfigUser && sortConfigUser.key === "agencyName" && sortConfigUser.direction === "asc" ? "desc" : "asc" })}>
                  Name{" "}
                  {sortConfigUser && sortConfigUser.key === "agencyName" && sortConfigUser.direction === "asc" ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Register Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agencyData?.map((agency) => (
                <TableRow key={agency.id} onClick={() => handleRowClick(agency)}>
                  <TableCell >
                    {agency.agencyName || "-"}
                  </TableCell>
                  <TableCell >
                    {agency.email || "-"}
                  </TableCell>
                  <TableCell >
                    {agency.mobile || "-"}
                  </TableCell>
                  <TableCell >
                    {(agency.state !== null ? agency.state + ", " : "") +
                      (agency.city !== null ? agency.city : "")}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={agency?.status === 1 ? true : false}
                      disabled={agency?.status == 2 ? true : false}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen({
                          id: agency?.id,
                          open: true,
                          currentComponent: "status",
                          head: "Change Status",
                          para: "Are you sure you want to Change the Status?",
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      color={agency.isRegistered === 1 ? "primary" : "warning"}
                      label={agency.isRegistered == 1 ? "Register" : "Not Register"}
                    />
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} gap={1} justifyContent={"flex-end"}>
                      <Tooltip title="Edit">
                        <IconButton
                          sx={{
                            backgroundColor: "primary.main",
                            color: "white.main",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();

                            setIsModalOpen({
                              open: true,
                              currentComponent: "addAgency",
                              isEdit: true,
                              id: agency.id,
                            });
                          }}
                        >
                          <MdEdit size={15} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          sx={{
                            backgroundColor: "red.main",
                            color: "white.main",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen({
                              open: true,
                              currentComponent: "delete",
                              head: "Delete Agency",
                              para: "Are you sure you want to Delete this Agency?",
                              id: agency.id,
                            });
                          }}
                        >
                          <MdDelete size={15} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {agencyData === null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : agencyData && agencyData.length === 0 ? (
                <TableRowsLoader rowsNum={10} colNumber={6} />
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        {agencyData != null && (
          <Pagination
            totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}

        {/* Dialog for Agency Details */}
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              borderRadius: 15,
              padding: "10px 20px",
              width: "100%",
              maxWidth: "400px", // dialog width
            },
          }}
        >
          <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
            <Heading smallHead={"Agency Details"} />
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          {singleAgencyData && (
            <DialogContent sx={{ mt: 2 }} style={{ padding: "0px !important" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" fontWeight="bold">
                    Name:
                  </Typography>
                  <Typography>{singleAgencyData.agencyName}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" fontWeight="bold">
                    Email:
                  </Typography>
                  <Typography>{singleAgencyData.email}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" fontWeight="bold">
                    Phone:
                  </Typography>
                  <Typography>{singleAgencyData.mobile}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" fontWeight="bold">
                    Contect Person:
                  </Typography>
                  <Typography>{singleAgencyData?.contactPerson || '-'}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" fontWeight="bold">
                    Contact Person Phone
                  </Typography>
                  <Typography>{singleAgencyData?.contactPhone || '-'}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" fontWeight="bold">
                    Status:
                  </Typography>
                  <Chip
                    label={singleAgencyData.isRegistered == 1 ? "Register" : "Not Register"}
                    color={
                      singleAgencyData.isRegistered != 0
                        ? "primary"
                        : "warning"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1" fontWeight="bold">
                    Location:
                  </Typography>
                  <Typography>
                    {[
                      singleAgencyData.address1,
                      singleAgencyData.address2,
                      singleAgencyData.city,
                      singleAgencyData.state,
                      singleAgencyData.zipcode,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography variant="body1" fontWeight="bold">
                    Services:
                  </Typography>
                  <Typography>
                    {singleAgencyData.agencyService?.map((service) => service.serviceName).join(", ")}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          )}

          <DialogActions>
            <Button
              onClick={handleClose}
              buttonName={"Close"}
              variant="contained"
              color="tertiary"
            />
          </DialogActions>
        </Dialog>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "" });
          getAgencyData();
        }}
        data={serviceData}
        handelClick={changeStatus}
      />
    </>
  );
};

export default TotalAgency;
