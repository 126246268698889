import React, { useEffect, useState } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Autocomplete,
  FormControl,
  Typography
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "..";
import { userInitialValues } from "../../../helper/initialValues";
import { useFormik } from "formik";
import { userValidationSchema } from "../../../helper/validation";
import { getPincodeList } from "../../../Service/Pincode.srvice";
import { userAdd, userUpdate, userDetails } from "../../../Service/User.service";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import Notification from "../Notification/Notification";
import { getAllStates, getCity } from "../../../Service/location.service";

const Add_user = ({ handleClose, modalOpen, handelClick, handleChange, status }) => {
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);

  const formik = useFormik({
    initialValues: userInitialValues,
    validationSchema: userValidationSchema,
    onSubmit: async (values) => {
      if (values.state == "") {
        setShowNotification({
          show: true,
          type: 'error',
          message: 'Zip code is invalid.',
        });
        return false;
      }
      const payload = {
        "firstName": values.firstName,
        "lastName": values.lastName,
        "email": values.email,
        "mobile": values.mobile,
        "address1": values.address1,
        "address2": values.address2,
        "state": values.state?.id,
        "city": values.city?.cityID,
        "zipcode": values.zipCode,
      }

      const res = modalOpen.isEdit ? await userUpdate(modalOpen.id, payload) : await userAdd(payload);
      if (res?.status) {
        handleClose();
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'User added successfully.',
        }));

        formik.resetForm();

      } else {
        // handleClose();
        setShowNotification({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong.',
        });
      }
    },
  }
  );



  const getUserDetails = async (id) => {
    const res = await userDetails(id);
    if (res?.status) {
      formik.setValues({
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        mobile: res.data.mobile,
        address1: res.data.address1,
        address2: res.data.address2,
        zipCode: res.data.zipcode,
        // state: res.data.state,
        // city: res.data.city,
        stateId: res.data.state,
        cityId: res.data.city,
      });
      handleCityDataByStateId(res.data.state);
    }
  }


  useEffect(() => {
    if (modalOpen && modalOpen.isEdit && modalOpen.id) {
      getUserDetails(modalOpen.id);
    }
  }
    , [modalOpen]);

  const getAllStatesData = async () => {
    try {
      const res = await getAllStates();
      if (res.status) {
        setAllStates(res.data);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleCityDataByStateId = async (stateData) => {
    if (stateData) {
      formik.setFieldValue('city', '');
      const CitiesData = await getCity(stateData && stateData?.id ? stateData.id : stateData);
      if (CitiesData.status) {
        setAllCities(CitiesData.data);
      }
    }
  }

  useEffect(() => {
    getAllStatesData();
  }, []);


  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? "Edit User" : "Add User"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: "900px" }}>
          <Grid item xs={6} sm={6}>
            <Input
              labelinput={"First Name"}
              type="text"
              maxLength={100}
              formik={formik}
              name="firstName"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Input
              labelinput={"Last Name"}
              type="text"
              maxLength={100}
              formik={formik}
              name="lastName"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput={"Email"}
              type="text"
              maxLength={50}
              formik={formik}
              name="email"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput={"Phone Number"}
              type="text"
              maxLength={50}
              formik={formik}
              name="mobile"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Address Line 1"}
              placeholder={"Address Line 1"}
              name="address1"
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Address Line 2"}
              name="address2"
              placeholder={"Address Line 2"}
              formik={formik}
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <FormControl sx={{ width: { xs: "100%", sm: "100%" } }}>
              <Typography fontSize={{ xs: '14px', sm: '14px' }} fontWeight={500}>Select State</Typography>
              <Autocomplete
                value={allStates.filter(state => state.id == formik.values.stateId).length > 0 ? allStates.filter(state => state.id == formik.values.stateId)[0] : null}
                onChange={(event, newValue) => {
                  if (newValue == null) {
                    formik.setFieldValue('city', '');
                  }
                  formik.setFieldValue('state', newValue);
                  formik.setFieldValue('stateId', newValue?.id);

                  handleCityDataByStateId(newValue)
                }}
                options={allStates}
                getOptionLabel={(option) => option.stateName || ""}
                renderInput={(params) => (
                  <Input {...params} variant="outlined" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={4}>
            <FormControl sx={{ width: { xs: "100%", sm: "100%" } }}>
              <Typography fontSize={{ xs: '14px', sm: '14px' }} fontWeight={500}>Select City</Typography>
              <Autocomplete
                value={allCities.filter(city => city.cityID == formik.values.cityId).length > 0 ? allCities.filter(city => city.cityID == formik.values.cityId)[0] : null}
                onChange={(event, newValue) => {
                  formik.setFieldValue('city', newValue);
                  formik.setFieldValue('cityId', newValue?.cityID);
                }}
                options={allCities}
                getOptionLabel={(option) => option.cityName || ""}
                renderInput={(params) => (
                  <Input {...params} variant="outlined" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              labelinput={"Zip Code"}
              name="zipCode"
              placeholder={"Zip Code"}
              maxLength={6}
              formik={formik}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName="Cancle" size="small" color='tertiary' onClick={handleClose} />
        <Button buttonName={modalOpen.isEdit ? "Save" : "Add"}
          size="small" color='primary' onClick={formik.handleSubmit} />
      </DialogActions>
      <Notification show={showNotification} setShow={setShowNotification} />

    </>
  );
};

export default Add_user;
