import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Button, Input } from "../../../components/Common";
import { CloseIcon } from "../../../helper/Icons";

const Search_Agency_View = ({
  handleClose,
  modalOpen,
  handelClick,
  handleChange,
  status,
}) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Agency Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: "600px" }}>
          {/* Agency Name */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Agency Name:</Typography>
            <Typography variant="body2">ABC Agency</Typography>
          </Grid>

          {/* Agency Number */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Agency Number:</Typography>
            <Typography variant="body2">123-456-7890</Typography>
          </Grid>

          {/* Agency Email */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Agency Email:</Typography>
            <Typography variant="body2">contact@abcagency.com</Typography>
          </Grid>

          {/* Agency Location */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Location:</Typography>
            <Typography variant="body2">123 Main St, Springfield, USA</Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1">Service:</Typography>
            <Typography variant="body2">
              ABA Therapy, OT Services, Evaluations, Social Skills
            </Typography>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="tertiary"
          onClick={handleClose}
        />
        {/* <Button
          buttonName="Add"
          size="small"
          color="primary"
          onClick={handelClick}
        /> */}
      </DialogActions>
    </>
  );
};

export default Search_Agency_View;
